<template>
  <div class="page-wrap">
    <div class="session-form-hold">
      <base-card>
        <v-progress-linear :active="loading" :indeterminate="loading" absolute top color="primary"></v-progress-linear>
        <v-card-text class="text-center">
          <v-avatar size="60" class="mb-4">
            <img src="@/assets/images/logo.svg" alt="" />
          </v-avatar>

          <h6 class="text--disabled font-weight-medium mb-10">
            {{ $t("auth.account_unblocking") }}
          </h6>

          <ca-alert v-show="alert.message" :type="alert.type">{{ alert.message }}</ca-alert>

          <p v-if="loading">{{ $t("common.wait") }}</p>

          <v-form>
            <div class="text-center">
              <v-btn text small color="primary" class="mb-2" :to="{ name: 'login' }">Log in</v-btn>
            </div>
          </v-form>
        </v-card-text>
      </base-card>
    </div>
  </div>
</template>

<script>
import CaAlert from "@/views/components/Alert";

export default {
  components: { CaAlert },
  metaInfo() {
    return {
      title: this.$t("titles.restore_access")
    };
  },
  data() {
    return {
      alert: {
        message: null,
        type: "warning"
      },
      show: false,
      loading: false
    };
  },
  computed: {
    token() {
      return this.$route.params.token;
    }
  },
  mounted() {
    this.restoreAccess();
  },
  methods: {
    restoreAccess() {
      this.loading = true;

      this.axios
        .post("auth/restore-access", {
          token: this.token
        })
        .then(response => {
          this.loading = false;

          if (response.data.success) {
            this.alert.message = this.$t("auth.account_is_unblocked");
            this.alert.type = "success";
          } else {
            this.alert.message = this.$t("auth.invalid_link");
            this.alert.type = "warning";
          }
        })
        .catch(error => {
          this.loading = false;

          const response = error.response;

          if (response.status === 422) {
            this.alert.message = this.$t("auth.invalid_link");
            this.alert.type = "warning";
          }
        });
    }
  }
};
</script>
